var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-settings pb-10"},[_c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('h1',{staticClass:"text-center"},[_vm._v(" System Users ")]),_c('p',{staticClass:"text-center"},[_vm._v(" The users listed below will be able to login and manage the system. ")]),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-right mt-3 mr-8",attrs:{"cols":"6"}},[(_vm.can('system-user-create'))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showAddSystemUserDialog = true}}},[_c('v-icon',{attrs:{"left":"","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Add System User ")],1):_vm._e()],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-md-6 text-center"},[_c('search-input',{attrs:{"filters":_vm.searchFilters,"active-filter":_vm.searchColumn},on:{"filter-change":_vm.onSearchFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.systemUsers,"loading":_vm.isSystemUsersLoading,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDisplayRole(item.roles))+" ")]}},(_vm.can('system-user-delete'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"ma-4":"","text":"","icon":"","disabled":_vm.getDisplayRole(item.roles) === 'super-admin'},on:{"click":function($event){_vm.getDisplayRole(item.roles) !== 'super-admin' ? _vm.showDeleteSystemUserDialog(item.id) : null}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)],1)]}}],null,true)},[(_vm.getDisplayRole(item.roles) === 'super-admin')?_c('span',[_vm._v("Super admin cannot be deleted")]):_c('span',[_vm._v("Delete")])])]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('add-system-user-dialog',{attrs:{"show-dialog":_vm.showAddSystemUserDialog,"user":_vm.systemUser},on:{"close":function($event){_vm.showAddSystemUserDialog = false
      _vm.user = {}},"user-added":function($event){return _vm.getSystemUsers()}}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"agree-text":'Delete'},on:{"cancel":function($event){_vm.showAddSystemUserDialog = false
      _vm.user = {}},"agree":function($event){return _vm.deleteSystemUser()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }